<template>
  <div class="category-list-skeleton">
    <TVBox
      v-for="index in 3"
      :key="index"
      :rise-on-hover="2"
      class="category-list-skeleton__body"
      padding="20px 16px"
    >
      <skeleton-bone class="category-list-skeleton__bone" />
    </TVBox>
  </div>
</template>

<script>
export default {}
</script>

<style lang="sass">
.category-list-skeleton
  display: flex
  flex-direction: column
  padding: 10px 64px 0

  &__body
    cursor: pointer
    margin: 20px 10px
    font-size: 20px

  &__bone
    height: 30px !important

</style>
