<template>
  <div>
    <CategoryList />
  </div>
</template>

<script>

import CategoryList from '@/components/CategoryList'

export default {
  name: 'Categories',
  components: {
    CategoryList
  }
}
</script>

