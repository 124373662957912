<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      header: {
        title: undefined,
        color: undefined,
        icon: null,
      },
    }
  },
  computed: {
    ...mapGetters([
      'selectedCategories',
    ]),
  },
   mounted() {
    this.loadHeader()
  },
  methods: {
    loadHeader() {
      const category = JSON.parse(localStorage.getItem('selectedCategory'))

      if (category) {
        this.header.title = category.name
        this.header.color = category.color
        if (category.icon) {
          this.header.icon = category.icon
        } else {
          this.header.showWhenBig = false
        }
      }

      if (this.selectedCategories.length > 0) {
        const selectedCategory = this.selectedCategories[this.selectedCategories.length - 1]
        this.header.title = selectedCategory.name
        this.header.color = selectedCategory.color || '#2CA4E8'
      }
    },
  },
}
</script>
