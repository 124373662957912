<script>
export default {
  methods: {
    getNextPath(links) {
      return links.reduce((result, link) => {
        if (link.rel === 'next') {
          result = link.href
        }
        return result
      }, null)
    },
  },
}
</script>
