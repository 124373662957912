<template>
  <div class="categories-list">
    <ContentHeader
      :title="headerTitle"
      :color="headerColor"
      :show-back-button="true"
      :show-logo-when-big="false"
      :show-logo-when-small="false"
      :back-button-name="headerPreviousTitle"
      @getBack="removeSelectedCategory"
    />

    <TVWrapper>
      <div class="categories-list-body__title">
        <Icon type="grid" />
        <h2 class="categories-list-body__title__text">
          Selecione
        </h2>
      </div>

      <CategoryListSkeleton v-if="loading" />
      <div
        v-if="!loading"
        class="categories-list-body"
      >
        <TVBox
          v-for="(category, index) in categories"
          :id="index === 0 ? 'v-step-0' : ''"
          :key="category.id"
          :rise-on-hover="2"
          class="categories-list-body__box"
          padding="20px 16px"
          @click.native="goToNextCategory(category)"
        >
          {{ category.name }}
        </TVBox>
      </div>
    </TVWrapper>
  </div>
</template>

<script>
import ContentHeader from '@/components/ContentHeader'
import CategoryListSkeleton from '@/components/CategoryListSkeleton'
import { mapActions, mapGetters } from 'vuex'
import getNextPath from '@/mixins/getNextPath'
import getVideosPath from '@/mixins/getVideosPath'
import loadHeader from '@/mixins/loadHeader'
import urls from '@/utils/urls'

export default {
  name: 'CategoryList',
  components: {
    ContentHeader,
    CategoryListSkeleton
  },
  mixins: [
    getNextPath,
    getVideosPath,
    loadHeader,
  ],

  data() {
    return {
      loading: false,
      categories: []
    }
  },

  computed: {
    currentPath() {
      return this.$route.fullPath
    },

    headerTitle() {
      return this.selectedCategories.length > 0
        ? this.selectedCategories[this.selectedCategories.length - 1].name
        : 'Canais'
    },

    headerPreviousTitle() {
      return this.selectedCategories.length > 1 ? this.selectedCategories[this.selectedCategories.length - 2].name : 'Anterior'
    },

    headerColor() {
      return '#2CA4E8'
    }
  },

  watch: {
    $route() {
      this.loadCategories()
    }
  },

  mounted() {
    this.loadCategories()
  },

  methods: {
    ...mapActions(['getCategoryByPath', 'addSelectedCategory', 'removeSelectedCategory']),

    async loadCategories() {
      this.loading = true

      try {
        this.categories = await this.getCategoryByPath(`${urls.SAS_TV_HOST}${this.$route.fullPath}`)
      } finally {
        this.loading = false
      }
    },

    goToNextCategory(category) {
      category.color = '#2CA4E8'
      category.icon = null

      this.addSelectedCategory(category)
      const nextCategoryPath = this.getNextPath(category.links)

      if (nextCategoryPath) {
        this.$router.push(`/channels/${this.$route.params.channel}/categories?${decodeURIComponent(nextCategoryPath.split('?').pop())}`)
      } else {
        const videosCategoryPath = this.getVideosPath(category.links)
        this.$router.push(`/channels/${this.$route.params.channel}/videos?${decodeURIComponent(videosCategoryPath.split('?').pop())}`)
      }
    },
  }
}
</script>

<style lang="sass">
.categories-list
  .wrapper
      min-height: 80vh
      background: $color-ice

      +mq-s
        margin: 0
        max-width: 100%

  .categories-list-body
    display: flex
    flex-direction: column
    padding: 10px 64px 0

    +mq-s
      padding: 10px 24px 12px

    &__box
      cursor: pointer
      margin: 10px 10px
      color: $color-primary
      font-size: 20px
      font-weight: 600

    &__title
      display: flex
      padding: 50px 64px 0
      font-size: 20px
      margin-left: 10px

      +mq-s
        padding: 40px 24px 12px

      &__text
        font-size: 20px
        color: $color-main
        margin-left: 5px
</style>
