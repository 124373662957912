<template>
  <div
    :class="[ showLogo() ? 'small-header' : '']"
    :style="{ 'background-color': background, 'background-image': `linear-gradient(180deg, ${background} 8%, ${background} 100%)` }"
    class="content__header"
  >
    <div
      :class="[ showLogo() ? 'small-header-wrapper' : '']"
      class="content__header__wrapper"
    >
      <div
        :style="{ 'flex-direction': showBackButton ? 'column' : 'column-reverse' }"
        :class="[ showLogo() ? 'small-header-wrapper-label' : '']"
        class="content__header__wrapper__label"
      >
        <div
          v-if="showBackButton"
          class="content__header__wrapper__label__back"
          @click="getBack"
        >
          <Icon type="arrow-left" />
          <h5>{{ backButtonName }}</h5>
        </div>

        <h4 class="content__header__wrapper__label__name">
          {{ title }}
        </h4>
      </div>
      <img
        v-if="showDefaultLogo()"
        :src="logo"
        class="content__header__wrapper__logo"
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ContentHeader',

  props: {
    title: {
      type: String,
      required: false,
      default: 'SAS Educação TV'
    },
    color: {
      type: String,
      required: false,
      default: '#143F72'
    },
    logo: {
      type: String,
      required: false,
      default: require.context('../assets/', false, /\.svg$/)('./header-category.svg')
    },
    showBackButton: {
      type: Boolean,
      required: false,
      default: false
    },
    showLogoWhenSmall: {
      type: Boolean,
      required: false,
      default: true
    },
    showLogoWhenBig: {
      type: Boolean,
      required: false,
      default: true
    },
    backButtonName: {
      type: String,
      required: false,
      default: 'Página Inicial'
    }
  },

  data() {
    return {
      isMenuVisible: false
    }
  },

  computed: {
    background() {
      if (this.$route.meta.isCustomColor) {
        return this.color !== '#143F72'
          ? this.color
          : '#2CA4E8'

      }

      return this.color
    },
  },

  methods: {
    getBack() {
      if (this.$route.query.qrcode) {
        this.$router.push({ name: 'home' })
      } else {
        this.$router.go(-1)
        this.$emit('getBack')
      }
    },
    showLogo() {
      return ((this.$mq === 'small' || this.$mq === 'x_small') && !this.showLogoWhenSmall)
    },
    showDefaultLogo(){
      return !this.showLogo() && this.showLogoWhenBig
    }
  }
}
</script>

<style lang="sass">

.content__header
  height: 120px

  +mq-s
    height: 200px

  +mq-xs
    height: 120px

  &__wrapper
    margin: 0 auto
    max-width: 1032px
    height: 100%
    display: flex
    justify-content: space-between

    +mq-l
      max-width: 720px

    +mq-m
      max-width: calc(100% - 48px)

    +mq-s
      max-width: calc(100% - 16px)
      flex-direction: column-reverse

    &__label
      display: flex
      justify-content: space-between
      align-self: flex-end
      height: 100%
      padding: 12px 12px
      box-sizing: border-box

      +mq-s
        align-self: flex-start

      &__back
        display: flex
        align-items: center
        color: white
        cursor: pointer

        +mq-s
          font-size: 13px

      &__name
        font-size: 36px
        color: white

        +mq-s
          font-size: 28px

        +mq-xs
          font-size: 24px

    &__logo
      height: 64px
      align-self: center

      +mq-s
        height: 256px
        max-width: 100%

      +mq-xs
        height: 70px

.small-header
  height: 120px

.small-header-wrapper
  height: 120px

.small-header-wrapper-label
  height: 100%

.content__header__wrapper__label__back
  h5
    font-size: 13px
</style>
