<script>
export default {
  methods: {
    getVideosPath(links) {
      return links.reduce((result, link) => {
        if (link.rel === 'videos') {
          result = link.href
        }
        return result
      }, null)
    },
  },
}
</script>
